.svg-toolbar {
    background: #fff;
    width: calc(100% - 420px);
    position: fixed;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #3f4652;
    left: 415px;
    top: 68px;
    white-space: nowrap;
    height: 50px;
    z-index: 99;

    .main-toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        padding: 0 24px 0 10px;
        ul {
            margin-bottom: 0;
            padding-left: 0;
        }

        .main-menu {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            list-style-type: none;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
        }

        .menu-item {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            padding: 2px 5px;
            margin: 0 5px;

            .color-picker-btn {
                width: 24px;
                height: 24px;
                border-radius: 4px;
                display: inline-block;
                box-shadow: rgba(0, 0, 0, 0.15) 1px 1px 6px inset, rgba(255, 255, 255, 0.25) -1px -1px 0px inset;
            }
        }

        li {
            &.menu-item {
                display: inline-block;
                padding: 15px;
                line-height: normal;
                position: relative;
                font-size: 14px;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }
            &.active-font-style {
                background-color: #ebebeb;
            }
        }

        .color-picker-popup {
            width: 220px;
            min-width: 220px;
            position: absolute;
            display: block;
            left: 0;
            top: 100%;
            background-color: #fff;
            -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
            box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
        }
    }
}
