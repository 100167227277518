

.container {
    width: 100%;
    padding: 1rem;
    margin: 0 auto;
}

section {
    margin: 0 1rem;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -1rem;
}

.card {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.card {
    background-color: rgba(0,0,0,0.4);
    z-index:1000;
    /* opacity: 0.4; */
}

.loading-wrapper {
    /* z-index:2000; */
    width: 9rem;
    height: 9rem;
    margin: 0 auto;
    position: relative;
}

.loading-devover,
.loading-devover:after {
    border-radius: 50%;
    width: 9rem;
    height: 9rem;
}

.loading-devover+.img {
    position: absolute;
    /* background-color: #ce3b3c; */
    padding: .3rem;
    top: 0;
    width: 75%;
    height: 75%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.loading-devover+.img img {
    width: 100%;
}

.loading-devover {
    border-radius: 50%;
    border-top: .7rem solid rgba(0, 0, 0, .05);
    border-right: .7rem solid rgba(0, 0, 0, .05);
    border-bottom: .7rem solid rgba(0, 0, 0, .05);
    border-left: .7rem solid #ce3b3c;
    position: relative;
    animation: first linear 1s infinite;
}

.loading-devover:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-radius: 50%;
}

.loading-name {
    text-align: center;
    margin-top: 2rem;
    color: #666;
}

@keyframes first {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}