* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body { 
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.workspace {
  display: flex;
  background-color: #fafafa;

  .main {
    overflow: hidden;
    position: relative;
    width: calc(100vw - 80px);
    display: inline-block;
  }
}
