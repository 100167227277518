
@font-face {
  font-family: "Audiowide";
  src: local("Audiowide"), url('./assets/fonts/Audiowide-Regular.ttf') format("truetype");
}
@font-face {
  font-family: "Bungee Outline";
  src: local("Bungee Outline"), url("./assets/fonts/BungeeOutline-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Cabin";
  src: local("Cabin"), url("./assets/fonts/Cabin-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Karla";
  src: local("Karla"), url("./assets/fonts/Karla-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Langar";
  src: local("Langar"), url("./assets/fonts/Langar-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  src: local("Lato"), url("./assets/fonts/Lato-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Merriweather";
  src: local("Merriweather"), url("./assets/fonts/Merriweather-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"), url("./assets/fonts/OpenSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans Condensed";
  src: local("OpenSans Condensed"), url("./assets/fonts/OpenSansCondensed-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Oswald";
  src: local("Oswald"), url("./assets/fonts/Oswald-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Playfair Display";
  src: local("Playfair Display"), url("./assets/fonts/PlayfairDisplay-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "PT Sans";
  src: local("PT Sans"), url("./assets/fonts/PTSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Raleway";
  src: local("Raleway"), url("./assets/fonts/Raleway-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Redressed";
  src: local("Redressed"), url("./assets/fonts/Redressed-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Condensed";
  src: local("Roboto Condensed"), url("./assets/fonts/RobotoCondensed-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto Mono";
  src: local("Roboto Mono"), url("./assets/fonts/RobotoMono-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Ubuntu";
  src: local("Ubuntu"), url("./assets/fonts/Ubuntu-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Yellowtail";
  src: local("Yellowtail"), url("./assets/fonts/Yellowtail-Regular.ttf") format("truetype");
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
