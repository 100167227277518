// .icon-info {
//     border-radius: 2px;
//     padding: 10px;
//     background: rgb(240, 240, 240);
//     border: none;
//     transition: all .3s;
//     cursor: pointer;
// }

// .icon-sub-info {
//     border-radius: 2px;
//     padding: 10px;
//     background: rgb(240, 240, 240);
//     border: none;
//     transition: all .3s;
//     cursor: pointer;
// }

// ul {
//     list-style-type: none;
//     margin: 0;
//     padding: 0;
//     overflow: hidden;
//     background-color: rgb(255, 255, 255);
// }

// li {
//     float: left;
// }

// li a {
//     display: block;
//     color: rgb(22, 11, 11);
//     text-align: center;
//     padding: 14px 16px;
//     text-decoration: none;

// }

// li a:hover {
//     background-color: #404762;
// }

.image-top-toolbar-wrapper {
    background: #fff;
    width: calc(100% - 420px);
    position: fixed;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #3f4652;
    left: 415px;
    top: 68px;
    white-space: nowrap;
    height: 50px;
    z-index: 99;

    .main-toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        padding: 0 24px 0 10px;

        .toolbar-text {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;

            ul {
                margin-bottom: 0;
                padding-left: 0;
            }

            .main-menu {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                list-style-type: none;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
            }

            .menu-item {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                padding: 2px 5px;
                margin: 0 5px;

                .color-picker-btn {
                    width: 24px;
                    height: 24px;
                    border-radius: 4px;
                    display: inline-block;
                    box-shadow: rgba(0, 0, 0, 0.15) 1px 1px 6px inset, rgba(255, 255, 255, 0.25) -1px -1px 0px inset;
                }
            }

            li {
                &.menu-item {
                    display: inline-block;
                    padding: 15px;
                    line-height: normal;
                    position: relative;
                    font-size: 14px;
                    cursor: pointer;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                }
                &.active-font-style {
                    background-color: #ebebeb;
                }
            }
        }

        .color-picker-popup {
            width: 220px;
            min-width: 220px;
            position: absolute;
            display: block;
            left: 0;
            top: 100%;
            background-color: #fff;
            -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
            box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
        }

        .text-align-popup {
            // width: 220px;
            // min-width: 180px;
            position: absolute;
            display: flex;
            left: 250px;
            top: 100%;
            background-color: #fff;
            -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
            box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
            li {
                list-style: none;
                margin: 10px;
                cursor: pointer;
            }
        }
    }
}

.top-fixed-message {
    width: 90%;
    margin: 10px auto;
    position: fixed;
    z-index: 100;
    .alert {
        padding: 20px;
        background-color: green; /* Red */
        color: #000;
        margin-bottom: 15px;
    }

    .closebtn {
        margin-left: 15px;
        color: #000;
        font-weight: bold;
        float: right;
        font-size: 22px;
        line-height: 20px;
        cursor: pointer;
        transition: 0.3s;
    }

    .closebtn:hover {
        color: black;
    }

    .alert-warning {
        background-color: #faebcc; /* Red */
        color: #8a6d3b;

        .closebtn {
            color: #8a6d3b;
        }
    }
}
