.aside-menu {
  // max-width: 400px;
  height: calc(100vh - 66px);
  // display: flex;
  overflow: hidden;
  z-index: 99;

  .aside-menu-list {
    max-width: 80px;
    height: inherit;
    background-color: #30475e;
    box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.15);
    // margin-top: 3px;

    li {
      color: #fff;
      display: block;
      padding: 12px;
      text-decoration: none;
      text-align: center;

      &:hover {
        background-color: #b3b9bf;
        /* Dark grey background on mouse-over */
        color: #000;

        span {
          color: #000;
        }
      }

      &.active {
        background-color: #d2d4db;
        color: #000;

        span {
          color: #000;
        }
      }

      svg {
        font-size: 30px;
        // margin: 0 auto;
        width: 100%;
      }

      span {
        display: block;
        font-size: 14px;
        text-align: center;
        margin-top: 5px;
        color: #fff;
      }
    }
  }
  .aside-submenu {
    width: 320px;
    z-index: 99;
    position: absolute;
    top: 66px;
    left: 80px;
    background: #d2d4db;
    box-shadow: 5px -2px 10px 0px rgba(0, 0, 0, 0.15);
    height: inherit;
    // transition: translateX 2s;
    // display: none;
    // transform: translateX(calc(-100%));
    opacity: 0;
    transition: opacity 0.4s linear;

    &.show {
      opacity: 1;
      // display: block;
      // transition: display 5s linear;
    }
    &.not-show {
      display: none;
    }

    .text-center {
      text-align: center;
    }

    .input-text-menu {
      padding: 20px 10px;

      .text-info {
        color: #4f5467;
        margin-bottom: 20px;
        display: block;
      }

      .input-heading {
        color: #4f5467;
        display: block;
        font-size: 42px !important;
        font-weight: 700;
      }

      .input-sub-heading {
        display: block;
        font-size: 30px !important;
        font-weight: 500;
        color: #4f5467;
      }

      .input-body-text {
        display: block;
        color: #4f5467;
        font-size: 16px !important;
      }

      .input-curved-text {
        display: block;
        color: #4f5467;
        font-size: 16px;
      }
    }

    span {
      font-size: 12px;
      color: #404762;

      &.active {
        padding: 3px 0;
        cursor: pointer;
      }
    }

    .image-menu-main {
      position: relative;
      min-height: 500px;
      height: calc(100vh - 243px);

      .main-clipart-image-list {
        position: relative;
        width: 100%;
        touch-action: auto;
        overflow-y: auto;
        height: calc(100vh - 153px);
        .main-clipart-image-spinner {
          margin-top: 10px;
          align-items: center;
          display: flex;
          justify-content: center;
        }
        .container {
          padding: 10px;
          margin-bottom: 30px;
          display: grid;
          grid-template-columns: 120px 120px;

          div {
            margin: auto;
            cursor: pointer;
            transition: box-shadow 0.2s;
            &:hover {
              box-shadow: 0 0 11px rgba(33, 33, 33, 0.1);
            }
            .shapes-svg {
              svg {
                height: 80px;
                width: 100px;
                object-fit: contain;
              }
            }
          }
        }
      }

      .main-shapes-image-list {
        position: relative;
        width: 100%;
        touch-action: auto;
        overflow-y: scroll;
        height: calc(100vh - 243px);

        .container {
          padding: 10px;
          margin-bottom: 30px;
          display: grid;
          grid-template-columns: 120px 120px;

          div {
            margin: auto;
            cursor: pointer;
            transition: box-shadow 0.2s;
            &:hover {
              box-shadow: 0 0 11px rgba(33, 33, 33, 0.1);
            }
            .shapes-svg {
              svg {
                height: 80px;
                width: 100px;
                object-fit: contain;
              }
            }
          }
        }
      }

      .menu {
        position: absolute;
        // width: 33.33%;
        padding: 10px;
        height: inherit;

        .image-menu {
          border-radius: 2px;
          border: 0;
          cursor: pointer;
          margin: 10px;

          .image-icon {
            border-radius: 4px;
            padding: 20px;
            background: #fff;
            border: none;
            transition: all 0.3s;
          }

          .image-info {
            justify-content: center;
            padding: 5px;
            display: flex;
            background-color: transparent;
            align-items: center;

            span {
              cursor: pointer;
              font-size: 12px;
              word-break: break-all;
              color: #404762;
            }

            .blank_row {
              height: 50px !important;
              background-color: #000000;
            }
          }
        }

        .image-url-input {
          margin-top: 0;
          padding-top: 20px;
          border-top: 1px solid #ebebeb;
          margin: 0 10px;
          position: relative;

          .form-group {
            margin-bottom: 15px;

            label {
              display: block;
              text-align: left;
              margin-bottom: 10px;
              font-weight: bold;
              width: 300px;
            }

            .input-group {
              display: flex;
              position: relative;
              border-collapse: separate;

              input {
                padding: 3px 5px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-right: none;
                width: 300px;
                border: 1px solid #404762;
                font-size: 12px;
                color: #404762;
                outline: none;
                border-radius: 2px;
              }

              button {
                border: none;
                background-color: #404762;
                color: #fff;
                margin: 0;
                border-radius: 0 3px 3px 0;
                padding: 0;
                color: #fff;
                background: #404762;
                display: inline-block;
                position: relative;
                cursor: pointer;
                min-height: 36px;
                min-width: 88px;
                line-height: 36px;
                vertical-align: middle;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                text-align: center;
                border-radius: 2px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                outline: none;
                border: 0;
                padding: 0 12px;
                margin: 0px 8px;
                white-space: nowrap;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 14px;
                font-style: inherit;
                font-variant: inherit;
                font-family: inherit;
                text-decoration: none;
                overflow: hidden;
              }
            }
          }
        }
      }

      .clipart-menu {
        position: relative;
        width: auto;
        padding: 10px;

        .clipart-icon {
          border-radius: 4px;
          padding: 20px;
          border: none;
          transition: all 0.3s;
          border: 2px solid #eee;

          img {
            height: 80px;
          }
        }
      }

      .clipart-category {
        margin-top: 10px;
        padding: 0 10px;
      }

      .draw-title {
        text-align: left;
        margin-bottom: 10px;
        display: block;
        font-size: 18px;
        font-weight: 500;
      }

      .palette-label {
        font-size: 15px;
        text-align: left;
        margin: 0 0 5px;
      }

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        appearance: none;
        outline: 0;
        box-shadow: none;
        border: 0 !important;
        background: #2c3e50;
        background-image: none;
      }

      select::-ms-expand {
        display: none;
      }

      .select {
        position: relative;
        display: flex;
        width: 10em;
        height: 3em;
        line-height: 3;
        background: #2a3947;
        overflow: hidden;
        border-radius: 0.25em;
      }

      select {
        flex: 1;
        padding: 0 0.5em;
        color: #fff;
        cursor: pointer;
        font-size: medium;
      }

      .select::after {
        content: "\25BC";
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 1em;
        background: #34495e;
        cursor: pointer;
        pointer-events: none;
        -webkit-transition: 0.25s all ease;
        -o-transition: 0.25s all ease;
        transition: 0.25s all ease;
      }

      .select:hover::after {
        color: #000;
      }

      .main-ranges {
        text-align: left;
        margin-bottom: 10px;
        font-size: 15px;
        color: #404762;

        .slidecontainer {
          width: 100%;
        }

        .slider {
          -webkit-appearance: none;
          width: 100%;
          height: 15px;
          border-radius: 5px;
          background: #000000;
          outline: none;
          opacity: 0.7;
          -webkit-transition: 0.2s;
          transition: opacity 0.2s;
        }

        .slider:hover {
          opacity: 1;
        }

        .slider::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background: #000000;
          cursor: pointer;
        }

        .slider-range {
          width: 220px;
          cursor: pointer;
          background: #000000;
        }
      }
    }
  }
}
