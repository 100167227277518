.dt-disclaimer-main {
    position: fixed;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -webkit-box-shadow: 1px 0 10px rgba(0, 0, 0, 0.15);
    box-shadow: 1px 0 10px rgba(0, 0, 0, 0.15);
    bottom: 110px;
    left: 0;
    background: #fff;
    display: flex;
    max-width: 265px;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    transition: all 1s;
    transform: translateX(calc(10px - 100%));
    border-right: 3px solid #404762;
    cursor: pointer;
    z-index: 999;

    &.show {
        transform: translateX(0);
    }

    .i-content {
        .i-text {
            position: relative;
            padding: 10px 10px 10px 0;
            margin-left: 10px;
        }
        .i-close {
            cursor: pointer;
            position: absolute;
            font-size: 20px;
            top: -10px;
            left: 20px;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
            background: #fff;
            border-radius: 50%;
            color: #888;
            display: inline-block;

            // &::before {
            //     content: "\E91E";
            // }
        }
    }
}
