.image-container {
    position: relative;
    height: 100%;

    .container-inner {
        height: 100%;

        .inner-wrapper {
            padding: 40px 50px 50px;
            overflow-y: auto;
            height: 100%;
            width: 100%;
            position: absolute;
            display: block;
            text-align: center;
            box-sizing: border-box;
            top: 0;
            left: 0;
            justify-content: center;
            align-items: center;

            .inner-main-wrap {
                display: flex;
                position: relative;
                background: #fff;
                width: fit-content;
                margin: 0 auto;

                .image-canvas-main {
                    // width: 700px;
                    // height: 700px;
                    position: relative;
                    background-color: #fff;
                    margin: auto 0;

                    .inner-image-background {
                        background-color: rgb(255, 255, 255);
                        position: absolute;
                        top: 0;
                        left: 0;
                        // width: 100%;
                        height: 100%;

                        img {
                            // width: 100%;
                            height: 100%;
                            // max-width: 100%;
                            vertical-align: middle;
                        }
                    }
                    .image-loader {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        justify-content: center;
                        display: flex;
                        align-items: center;
                        z-index: 10;
                        background-color: rgba(230, 230, 230, 0.95);
                    }
                    .canvas-wrapper {
                        position: absolute;
                        // width: 350px;
                        // height: 600px;
                        clip-path: none;
                        background-color: transparent;

                        .canvas-area {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            border: 1px dashed;
                            
                            &.selected {
                                border: 1px dashed orangered;
                            }

                            &.circleRadius {
                                border-radius: 100%;
                            }

                            .main-design-canvas.circleRadius {
                                border-radius: 100%;
                            }

                            .main-design-canvas,
                            .main-design-canvas-two-side {
                                position: absolute;
                                touch-action: none;
                                user-select: none;
                                // cursor: pointer;
                                left: 0;
                                top: 0;
                                // &:hover {
                                //     border: .2px solid #d0d0d0;
                                //     box-shadow: 1px 0 8px rgba(0, 0, 0, 0.08);
                                // }
                            }
                        }
                    }
                }
                .image-right-menu {
                    border-left: 1px dashed #d2d4db;
                    margin: 40px 5px;
                    width: 150px;

                    .btn-wrapper {
                        display: flex;
                        border-bottom: 1px dashed #d2d4db;

                        .btn-undo-btn {
                            padding: 8px 15px;
                            border-radius: 5px;
                            background: #fff;
                            color: #30475e;
                            cursor: pointer;
                            margin-right: 10px;
                            border: 1px solid #30475e;
                            width: fit-content;
                            margin: 10px auto;

                            &:hover {
                                background: #30475e;
                                color: #fff;
                            }
                        }
                    }

                    .right-menu-fullwrap {
                        .container {
                            display: block;
                            position: relative;
                            padding-left: 35px;
                            // margin-bottom: 12px;
                            cursor: pointer;
                            font-size: 20px;
                            -webkit-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                        }

                        /* Hide the browser's default checkbox */
                        .container input {
                            position: absolute;
                            opacity: 0;
                            cursor: pointer;
                            height: 0;
                            width: 0;
                        }

                        /* Create a custom checkbox */
                        .checkmark {
                            position: absolute;
                            top: 14px;
                            left: 10px;
                            height: 24px;
                            width: 24px;
                            background-color: #eee;
                        }

                        /* On mouse-over, add a grey background color */
                        .container:hover input ~ .checkmark {
                            background-color: #ccc;
                        }

                        /* When the checkbox is checked, add a blue background */
                        .container input:checked ~ .checkmark {
                            background-color: #2196f3;
                        }

                        /* Create the checkmark/indicator (hidden when not checked) */
                        .checkmark:after {
                            content: "";
                            position: absolute;
                            display: none;
                        }

                        /* Show the checkmark when checked */
                        .container input:checked ~ .checkmark:after {
                            display: block;
                        }

                        /* Style the checkmark/indicator */
                        .container .checkmark:after {
                            left: 9px;
                            top: 5px;
                            width: 5px;
                            height: 10px;
                            border: solid white;
                            border-width: 0 3px 3px 0;
                            -webkit-transform: rotate(45deg);
                            -ms-transform: rotate(45deg);
                            transform: rotate(45deg);
                        }
                    }
                }
            }
        }

        .image-thumbnails {
            display: flex;
            margin-top: 30px;

            .thumbnail {
                display: grid;
                margin-right: 20px;
                text-align: center;

                &.active {
                    border: 1px solid #768290;
                }

                &:hover {
                    border: 1px solid #d0d6dd;
                }
            }
        }

        .image-variants {
            .thumbnail {
                display: inline-grid;
                margin-top: 20px;
                text-align: center;

                &.active {
                    border: 1px solid #768290;
                }

                &:hover {
                    border: 1px solid #d0d6dd;
                }
            }
        }
    }
}

.main {
    .main-right-menu {
        background-color: #fff;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14);

        ul {
            list-style-type: none;
            display: inline-block;
            margin: 10px 0;

            li {
                width: 100%;
                padding: 0 10px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: start;
                -ms-flex-pack: start;
                justify-content: flex-start;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                font-size: 13px;
                line-height: 36px;
                cursor: pointer;

                &:hover {
                    background-color: #eee;
                }
            }
        }
    }
}

.preview-modal-body {
    .preview-modal-container {
        display: flex;
        margin-top: 10px;

        .image-list {
            display: grid;
            grid-template-columns: 300px 300px;
            column-gap: 50px;
            margin: 10px auto;
            row-gap: 50px;

            .image-preview {
                display: grid;
                text-align: center;
                margin: 0 10px;

                img {
                    cursor: pointer;
                    margin: 0 auto;
                }
            }
        }
    }
    .footer {
        margin-top: 50px;
        .hint {
            font-size: 14px;
        }

        .proceed-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .btn-dwnld-btn {
                padding: 8px 15px;
                border-radius: 5px;
                background: #30475e;
                color: #fff;
                cursor: pointer;
                margin-right: 10px;
            }
        }
    }
}

.image-fullscreen {
    z-index: 9999;
    position: fixed;
    margin: auto;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    .btn-dwnld-btn {
        padding: 8px 15px;
        border-radius: 5px;
        background: #30475e;
        color: #fff;
        cursor: pointer;
        margin-right: 10px;
    }
    img {
        margin: auto;
        max-width: 800px;
    }
}

.modern-radio-container {
    margin: 24px auto;
    width: fit-content;
    display: flex;
    cursor: pointer;
    user-select: none;
}

.radio-outer-circle {
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    border: 2px solid #0d5bc6;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    transition: all 0.1s linear;
}

.radio-inner-circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #0d5bc6;
    transition: all 0.1s linear;
}
.unselected {
    border: 2px solid #666;
}
.unselected-circle {
    width: 0;
    height: 0;
}
.helper-text {
    color: #666;
    padding-right: 8px;
}
