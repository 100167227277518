.aside-menu .aside-submenu .text-menu-main,
.aside-menu .aside-submenu .image-menu-main {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: .5s;
  animation-name: animatebottom;
  animation-duration: .5s
}
@-webkit-keyframes animatebottom {
  from { bottom:-50px; opacity:0 } 
  to { bottom:0px; opacity:1 }
}
@keyframes animatebottom { 
  from{ bottom:-50px; opacity:0 } 
  to{ bottom:0; opacity:1 }
}

.aside-menu .aside-submenu .image-menu-main .clipart-category {padding: 0px;}
.aside-menu .aside-submenu .image-menu-main .clipart-category > div {margin: 0 10px;box-shadow: 0px 0px 5px rgb(118 130 144 / 64%);}
.aside-menu .aside-submenu .image-menu-main .clipart-category > div > div {background-color: #4f5467;}
.aside-menu .aside-submenu .image-menu-main .clipart-category > div > div * {color:#fff}
.aside-menu .aside-submenu .image-menu-main .menu {width: 100%;}
.aside-menu .aside-submenu .image-menu-main .menu > div {width: auto;display: inline-block !important;position: relative;}
.aside-menu .aside-submenu .image-menu-main .menu > div > .image-menu {float: left;display: inline-block;}
.aside-menu .aside-submenu .image-menu-main .menu > .image-url-input {width: 100%;}
.aside-menu .aside-submenu .image-menu-main .main-clipart-image-list .container {display: inline-block;width: 100%;}
.aside-menu .aside-submenu .image-menu-main .main-clipart-image-list .container > div {background-color: #fff;display: inline-block;width: 44%;text-align: center;margin: 5px;}


.dt-disclaimer-main { transform: translateX(calc(15px - 100%));}
.dt-disclaimer-main .i-content .i-close {width: 20px;text-align: center;font-size: 12px;height: 20px;line-height: 22px;font-weight: 900;right: 10px;left: auto;}
.dt-disclaimer-main .i-icon {width: 30px;display: inline-block;height: 60px;order: 2;position: relative;}
.dt-disclaimer-main .i-icon:after {content: "";width: 15px;height: 2px;background-color: #000;display: inline-block;position: absolute;top: 33px;right: 0;transform: rotate(-45deg);}
.dt-disclaimer-main .i-icon:before {content: "";width: 15px;height: 2px;background-color: #000;display: inline-block;position: absolute;top: 24px;right: 0;transform: rotate(45deg);}
.dt-disclaimer-main.show .i-icon {display:none}
.dt-disclaimer-main .i-icon {
	-moz-animation: bounce 3s infinite;
	-webkit-animation: bounce 3s infinite;
	animation: bounce 3s infinite;
}
@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -moz-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  60% {
    -moz-transform: translateX(-15px);
    transform: translateX(-15px);
  }
}
@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  60% {
    -webkit-transform: translateX(-15px);
    transform: translateX(-15px);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  60% {
    -moz-transform: translateX(-15px);
    -ms-transform: translateX(-15px);
    -webkit-transform: translateX(-15px);
    transform: translateX(-15px);
  }
}