.App-header {
  overflow: hidden;
  background-color: #283951;
  padding: 10px 10px;
  box-shadow: 1px 0 10px rgba(0, 0, 0, 0.15);

  a {
    float: left;
    color: black;
    text-align: center;
    padding: 5px 10px;
    text-decoration: none;
    font-size: 12px;
    line-height: 25px;
    border-radius: 4px;

    &.logo {
      cursor: pointer;
      font-size: 20px;
      font-weight: bold;
      display: none;
    }
    // &:hover {
    // background-color: #ddd;
    // color: black;
    // }

    &.active {
      background-color: dodgerblue;
      color: white;
    }
  }

  .header-right {
    float: right;

    .btn-wrapper {
      display: flex;
    }

    .btn-dwnld-btn {
      padding: 8px 15px;
      border-radius: 5px;
      background: #30475e;
      color: #fff;
      cursor: pointer;
      margin-right: 10px;
    }
  }
}

.share-modal-header {
  padding-bottom: 10px;
  border-bottom: 1px solid #c8c8c8;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 20px;
    font-weight: 600;
  }
  .btn-dwnld-btn {
    cursor: pointer;
    font-size: 20px;
    color: #4f5053;
    &:hover {
      color: #000;
    }
  }
}

.share-modal-container {
  
  height: 80%;
  display: flex;
  align-items: center;
  &.email {
    height: auto; 
  }
  div {
    width: 100%;
    .modal-text {
      display: block;
      margin: 8px 0;
      font-size: 18px;
      font-weight: 600;
    }

    .url-input-wrapper {
      border: 1px solid #7e7f83;
      border-radius: 3px;
      display: flex;
      justify-content: space-between;
      height: 40px;
      align-items: center;

      input {
        border: none;
        outline: none;
        width: 90%;
        margin-left: 10px;
        &:focus {
          outline: none;
        }
      }

      span {
        cursor: pointer;
        display: block;
        padding: 5px;
        border-left: 1px solid #7e7f83;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .header a {
    float: none;
    display: block;
    text-align: left;
  }

  .header-right {
    float: none;
  }
}
